import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationBarService } from 'src/app/services/notification-bar.service';

interface TexteditorOptions {
	name: string;
	value?: string;
	json?: boolean;
	label?: string;
	hint?: string;
	btnText?: string;
	callback?: (val: string) => Promise<string | undefined>;
}

@Component({
	selector: 'app-texteditor-dialog',
	templateUrl: './texteditor-dialog.component.html',
	styleUrls: ['./texteditor-dialog.component.scss'],
})
export class TexteditorDialogComponent {
	public name: string = 'Text Editor';
	public value: string = '';
	public json: boolean | undefined;

	public isUpdating = false;

	constructor(
		private notify: NotificationBarService,
		public dialogRef: MatDialogRef<TexteditorDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: TexteditorOptions
	) {
		this.name = data.name;
		if (data.value) {
			this.value = data.value;
			this.json = data.json;
		}
	}

	@ViewChild('autosize') autosize!: CdkTextareaAutosize;

	public async update(text?: string) {
		this.isUpdating = true;
		let result: any = undefined;

		const value = this.data.json ? JSON.parse(text || '{}') : text;

		if (this.data.callback) {
			try {
				result = await this.data.callback(this.data.json ? JSON.parse(value) : value);
			} catch (err) {
				if (err instanceof Error) {
					result = err.message;
				} else if (err instanceof HttpErrorResponse) {
					result = err.error.message;
				} else {
					result = err;
				}
			}
		}

		if (result === undefined) {
			this.dialogRef.close(value);
		} else {
			this.notify.notify('error', 'Error', result);
		}

		this.isUpdating = false;
	}

	public cancel() {
		this.dialogRef.close();
	}
}

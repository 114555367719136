<mat-form-field>
	@if (label) {
		<mat-label>{{ label }}</mat-label>
	}
	<input
		matInput
		[(ngModel)]="monthAndYear"
		[matDatepicker]="picker"
		[min]="minDate"
		[max]="maxDate"
		(dateChange)="emitDateChange($event)"
	/>
	<mat-datepicker-toggle matIconSuffix [for]="picker"> </mat-datepicker-toggle>
	<mat-datepicker #picker [startView]="'year'" (monthSelected)="monthChanged($event, picker)"> </mat-datepicker>
</mat-form-field>

<div fxLayout="column" fxLayoutGap="16px">
	<p class="h2">{{ name }}</p>
	<div fxLayout="column">
		<mat-form-field>
			<mat-label> {{ data.label }} </mat-label>
			<textarea
				matInput
				cdkTextareaAutosize
				#autosize="cdkTextareaAutosize"
				cdkAutosizeMinRows="1"
				cdkAutosizeMaxRows="5"
				[value]="value"
				#text
			>
			</textarea>
			@if (data.hint) {
				<mat-hint>{{ data.hint }}</mat-hint>
			}
		</mat-form-field>
	</div>
	<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
		@if (isUpdating) {
			<mat-spinner [diameter]="24"></mat-spinner>
		}
		<button mat-raised-button (click)="cancel()" [disabled]="isUpdating">Cancel</button>
		<button mat-raised-button color="primary" (click)="update(text.value)" [disabled]="isUpdating">
			{{ data.btnText || 'Update' }}
		</button>
	</div>
</div>
